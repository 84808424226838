import axios from "axios";
import moment from "moment";
import DataFormatter from "@/services/data-formatter.js";
import { apiUrl } from "@/services/api.js";

const state = {
  releases: [],
  latest: localStorage.getItem("songfile-latest-release") || null,
  status: "idle",
  current: null,
  rawCartData: null
};

const getters = {
  getReleases: state => {
    return state.releases;
  },
  getReleaseById: state => id => {
    return state.releases.find(i => i.id == id);
  },
  isItemOnRelease: state => id => {
    if (state.current) {
      return (
        state.current.items.find(item => item.songCode == id) !== undefined
      );
    } else {
      return false;
    }
  },
  getAllItems: state => {
    let items = [];

    if (state.releases.length) {
      for (const release of state.releases) {
        // items = items.concat(release.items);

        for (const item of release.items) {
          const index = items.findIndex(
            i => i.cartId == item.cartId && i.config == item.config
          );
          if (index >= 0) {
            items[index]["recordingType"] = item.config;
            items[index]["units"] += item.units;
          } else {
            items.push(item);
          }
        }
      }
    }

    return items;
  },
  getAllCartItems: (state, getters) => {
    return getters.getAllItems.filter(i => i.flag == "cart");
  },
  getAllSavedItems: (state, getters) => {
    return getters.getAllItems.filter(i => i.flag == "saved");
  },
  getReleaseItems: (state, getters) => id => {
    return getters.getAllItems.filter(i => i.releaseId == id);
  },
  royaltyAmountSubtotal: (state, getters) => {
    let total = 0;
    for (const item of getters.getAllCartItems) {
      if (!isNaN(item.royaltyAmount)) {
        total = total + item.royaltyAmount;
      }
    }
    return total;
  },
  processingFeesTotal: (state, getters) => {
    return getters.getAllCartItems.reduce(
      (total, item) => total + item.processingFee,
      0
    );
  },
  total: (state, getters) => {
    return getters.royaltyAmountSubtotal + getters.processingFeesTotal;
  },
  releaseDate: state => {
    if (state.current.release_date)
      return moment(state.current.release_date, "YYYY-MM-DD");

    return null;
  },
  manufactureDate: state => {
    if (state.current.manufacturing_date)
      return moment(state.current.manufacturing_date, "YYYY-MM-DD");

    return null;
  },
  invalidNoiDigital: (state, getters, rootState, rootGetters) => {
    return noi => {
      if (noi === "N" || !state.current.selected_types.includes("digital"))
        return false;

      return (
        (getters.releaseDate &&
          getters.releaseDate.isBefore(moment().subtract(25, "days"))) ||
        !rootGetters["auth/isRecordCompany"]
      );
    };
  },
  invalidNoiPhysical: (state, getters) => {
    return noi => {
      if (noi === "N" || !state.current.selected_types.includes("physical"))
        return false;

      return (
        (getters.releaseDate &&
          getters.releaseDate.isBefore(moment().add(5, "days"))) ||
        (getters.manufactureDate &&
          getters.manufactureDate.isBefore(moment().subtract(25, "days")))
      );
    };
  }
};

const actions = {
  async recoverReleases({ state, commit, dispatch, rootGetters }) {
    if (!rootGetters["auth/isLoggedIn"]) {
      return false;
    }

    const user = rootGetters["auth/getUser"];
    const loops = ["Songfile_Get_Cart_Values", "Songfile_Get_Saved_Items"];
    let releases = [];
    state.status = "working";

    const sortDesc = function(key) {
      return function(a, b) {
        let comparison = 0;

        if (a[key] > b[key]) {
          comparison = 1;
        } else if (a[key] < b[key]) {
          comparison = -1;
        }

        return comparison;
      };
    };

    await axios
      .get(`${apiUrl()}/cart?userid=${user.user_id}`)
      .then(function(response) {
        commit("setRawCartData", response.data);

        for (const loop of loops) {
          response.data[loop].sort(sortDesc("Cart ID"));

          for (const item of response.data[loop]) {
            let release;
            release = releases.find(i => i.id == item["Release_id"]);

            if (!release) {
              release = DataFormatter.buildRelease(item, item["Release_id"]);
              releases.push(release);
            }

            release.numberOfCopies[item["Config"]] = parseInt(item["Units"]);

            if (item.label_name === undefined) {
              const itemLabel = response.data[loop].find(i => {
                return (
                  i["Cart ID"] == item["Cart ID"] && i.label_name !== undefined
                );
              });
              if (itemLabel) {
                item.label_name = itemLabel.label_name;
              }
            }

            const song = DataFormatter.buildSong(release, item, loop);
            release.items.push(song);
          }
        }

        releases.sort(sortDesc("id"));

        const newState = {
          releases: releases
        };
        commit("setState", newState);
        state.status = "idle";
      });

    return true;
  },
  createRelease({ state, commit, rootGetters }, { release, copies }) {
    release.numberOfCopies = {};
    release.items = [];

    const new_release = DataFormatter.releaseCreate(release, copies);
    new_release.user_id = rootGetters["auth/getUser"].user_id;

    return new Promise((resolve, reject) => {
      axios
        .post(`${apiUrl()}/releases`, new_release)
        .then(function(response) {
          for (let type in release.copies) {
            release.numberOfCopies[type] = 0;

            for (let subtype in release.copies[type]) {
              release.numberOfCopies[type] += parseInt(
                release.copies[type][subtype]
              );
            }
          }

          let releases = [...state.releases];

          release.id = response.data.release_id;
          releases.push(release);

          commit("setState", {
            releases: releases,
            latest: release.id
          });
          commit("saveLatest", release.id);
          resolve(release);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  updateRelease({ commit, dispatch, rootGetters }, { release, copies }) {
    const new_release = DataFormatter.releaseCreate(release, copies);
    new_release.user_id = rootGetters["auth/getUser"].user_id;
    new_release.release_id = release.id;

    return new Promise((resolve, reject) => {
      axios
        .put(`${apiUrl()}/releases`, new_release)
        .then(function() {
          commit("setCurrent", release);
          dispatch("recoverReleases");
          resolve(release);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  addItemToRelease({ dispatch, rootGetters }, { song, release, reloadCart }) {
    const params = DataFormatter.postCartItem(song, release);
    params.user_id = rootGetters["auth/getUser"].user_id;

    return new Promise(resolve => {
      axios.post(`${apiUrl()}/cart`, params).then(async function() {
        release.items.push({
          id: song.song_code,
          originalId: song.song_code,
          songCode: song.song_code,
          title: song.title,
          release: release.album,
          releaseId: release.id,
          licenseRequest: { ...song.licenseRequest },
          flag: "cart"
        });

        if (reloadCart === true) {
          await dispatch("recoverReleases");
        }
        resolve();
      });
    });
  },
  async updateReleaseItem({ getters, dispatch, rootGetters }, item) {
    const release = getters.getReleaseById(item.releaseId);
    const params = DataFormatter.putCartItem(item, item.flag);
    params.user_id = rootGetters["auth/getUser"].user_id;

    await axios.put(`${apiUrl()}/cart`, params);
    await dispatch("recoverReleases");

    return true;
  },
  async removeReleaseItem({ dispatch }, item) {
    await axios.delete(`
      ${apiUrl()}/cart?sf_cart_id=${item.cartId}
    `);
    await dispatch("recoverReleases");
    return true;
  },
  async setItemFlag({ dispatch, rootGetters }, { item, flag }) {
    const params = DataFormatter.putCartItem(item, flag);
    params.user_id = rootGetters["auth/getUser"].user_id;

    await axios.put(`${apiUrl()}/cart`, params);
    await dispatch("recoverReleases");

    return true;
  },
  checkout({ commit, getters, dispatch }, data) {
    return new Promise((resolve, reject) => {
      dispatch(
        "projects/createProject",
        { ...data, items: getters.getAllCartItems },
        { root: true }
      ).then(
        project => {
          commit("clearReleases");
          resolve(project);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  async getLatestRelease({ state, dispatch, commit, getters, rootGetters }) {
    if (state.latest == null) {
      throw "No latest releaseID found";
    }

    const user = rootGetters["auth/getUser"];
    const latest = parseInt(state.latest);

    try {
      const release = await dispatch("getRelease", latest);
      release.items = getters.getReleaseItems(release.id);
      commit("setCurrent", release);
      return true;
    } catch (error) {
      return error;
    }
  },
  async getRelease({ rootGetters }, id) {
    const user = rootGetters["auth/getUser"];

    try {
      const response = await axios.get(
        `${apiUrl()}/${user.user_id}/releases/${id}`
      );
      return DataFormatter.getRelease(response.data.release_detail[0]);
    } catch (error) {
      if (error.response) {
        throw error.response.data.error;
      } else if (error.request) {
        throw error.request;
      } else {
        throw error;
      }
    }
  },
  async setCurrentRelease({ dispatch, commit }, id) {
    const release = await dispatch("getRelease", id);
    commit("setCurrent", release);
    return true;
  },
  async isNoiValid({ state, rootGetters }) {
    const userId = rootGetters["auth/getUser"].user_id;

    const { data } = await axios.get(
      `${apiUrl()}/cart/validate?user_id=${userId}`
    );

    return data.error === undefined;
  }
};

const mutations = {
  setState(state, { releases, latest }) {
    state.releases = releases;

    if (latest) {
      state.latest = latest;
    }
  },
  setRawCartData(state, data) {
    state.rawCartData = data;
  },
  saveLatest(state, id) {
    state.current = null;
    localStorage.setItem("songfile-latest-release", id);
  },
  setCurrent(state, release) {
    state.current = release;
  },
  clearReleases(state) {
    state.releases = [];
    state.current = null;
    localStorage.removeItem("songfile-latest-release");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
