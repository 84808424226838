import axios from "axios";
import { apiUrl } from "@/services/api.js";
import hostedFields from "braintree-web/hosted-fields";
import braintreeClient from "braintree-web/client";
import dataCollector from "braintree-web/data-collector";

const state = {
  clientToken: "",
  clientInstance: null,
  deviceData: null,
  paymentInstance: null,
  transactionId: null,
  isValid: false
};

const getters = {};

const actions = {
  async setClientToken({ state }) {
    try {
      const { data } = await axios.get(`${apiUrl()}/cart/token`);
      state.clientToken = data.client_token;
    } catch (err) {
      console.error("Can't get Braintree Client Token");
      throw new Error(err);
    }
  },
  async createClientInstance({ state, dispatch }) {
    if (!state.clientToken) {
      await dispatch("setClientToken");
    }

    try {
      state.clientInstance = await braintreeClient.create({
        authorization: state.clientToken
      });
    } catch (err) {
      console.error("Can't create client instance.");
      throw new Error(err);
    }

    try {
      const { deviceData } = await dataCollector.create({
        client: state.clientInstance
      });
      state.deviceData = deviceData;
    } catch (err) {
      console.error("Can't create dataCollector instance.");
      throw new Error(err);
    }
  },
  async createCreditCardFields({ state, dispatch }) {
    if (!state.clientInstance) {
      await dispatch("createClientInstance");
    }

    const options = {
      styles: {
        input: {
          "font-size": "16px",
          "font-family": "'Roboto Condensed', sans-serif",
          "font-weight": "lighter",
          color: "#ccc"
        },
        ":focus": {
          color: "black"
        },
        ".valid": {
          color: "#8bdda8"
        }
      },
      fields: {
        number: {
          selector: "#card-number",
          placeholder: "1111 1111 1111 1111"
        },
        cvv: {
          selector: "#cvv",
          placeholder: "123"
        },
        expirationDate: {
          selector: "#expiration-date",
          placeholder: "MM/YY"
        },
        postalCode: {
          selector: "#postal-code",
          placeholder: ""
        }
      }
    };

    options.client = state.clientInstance;

    try {
      state.paymentInstance = await hostedFields.create(options);
    } catch (err) {
      throw new Error(err);
    }

    state.paymentInstance.on("validityChange", event => {
      this.isValid = Object.keys(event.fields).every(key => {
        return event.fields[key].isValid;
      });
    });
  },
  async submitPayment({ state, dispatch }, args) {
    let nonce,
      checkout = {};

    try {
      const payload = await state.paymentInstance.tokenize({});
      nonce = payload.nonce;
    } catch (err) {
      console.error("Can't get tokenized paymentInstance");
      throw new Error(err);
    }

    try {
      const params = {
        payment_method_nonce: nonce,
        fee: args.fee.toFixed(2),
        email: args.email
      };

      const { data } = await axios.post(`${apiUrl()}/cart/transaction`, params);

      checkout.transactionId = data.transaction.id;
      checkout.cardType = data.transaction.credit_card.card_type;
    } catch (err) {
      console.error("Can't POST transaction data");
      throw new Error(err);
    }

    try {
      dispatch("gtmPurchase", checkout.transactionId);
      await dispatch("release/checkout", checkout, { root: true });
      return checkout.transactionId;
    } catch (err) {
      console.error("Can't finish Checkout process");
      return err;
    }
  },
  gtmPurchase({ rootGetters }, transactionId) {
    if (window.dataLayer) {
      const cartItems = rootGetters["release/getAllCartItems"];
      const items = [];
      cartItems.forEach(i => {
        items.push({
          song_name: i.songTitle,
          artist: i.licenseRequest.artists,
          price: parseFloat((i.royaltyAmount + i.processingFee).toFixed(2)),
          id: i.cartId,
          config: i.config
        });
      });
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          transaction_id: transactionId,
          value: parseFloat(rootGetters["release/total"].toFixed(2)),
          currency: "USD",
          items: items
        }
      });
    }
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
