import axios from "axios";
import moment from "moment";
import DataFormatter from "@/services/data-formatter.js";
import { apiUrl } from "@/services/api.js";

const state = {
  list: [],
  reOrderStatus: null,
  fromReorder: false
};

const getters = {
  url: (state, getters, rootState, rootGetters) => {
    return `${apiUrl()}/${rootGetters["auth/getUser"].user_id}/orders`;
  },
  getProject: state => order => {
    return state.list.find(i => i.order_number == order);
  },
  projectDetails: () => project => {
    let licenses = [];

    for (let item of project.items) {
      for (let type in item.licenseRequest.numberOfCopies) {
        let license = {
          song_title: item.song_title,
          play_length: item.licenseRequest.playtime,
          performing_artist: item.licenseRequest.artists,
          license_number: Math.ceil(Math.random() * 10000),
          units: item.licenseRequest.numberOfCopies[type],
          license_type: type
        };

        licenses.push(license);
      }
    }

    return licenses;
  },
  getReOrderStatus: state => state.reOrderStatus,
  isFromReorder: state => state.fromReorder
};

const actions = {
  async getProjects({ commit, getters }) {
    let items = [];

    await axios.get(getters.url).then(response => {
      const orders = response.data["Songfile_Get_Order"];

      for (const order of orders) {
        items.push({
          braintree_id: order["Braintree ID"],
          order_number: order.order_id,
          license_date: order.order_date,
          total_amt: order.total_amt,
          total_processing_fee: order.total_processing_fee,
          total_void: order["Total voided amount"],
          items: []
        });
      }
    });

    commit("setProjects", items);
  },
  createProject({ commit, getters, rootGetters }, data) {
    return new Promise((resolve, reject) => {
      const project = {
        project_name: data.items[0].licenseRequest.projectName,
        order_number: Math.ceil(Math.random() * 100000),
        artists: [],
        writers: [],
        status: 0,
        units: 0,
        license_date: new Date(),
        items: data.items,
        total: rootGetters["release/total"]
      };

      const params = {
        userid: rootGetters["auth/getUser"].user_id,
        pnref: data.transactionId,
        braintree_id: data.transactionId,
        payment_type: DataFormatter.paymentType(data.cardType)
      };

      function isValidUrl(string) {
        try {
          new URL(string);
          return true;
        } catch (err) {
          return false;
        }
      }

      axios.post(getters.url, params).then(
        res => {
          res.data.urls.forEach(url => {
            if (isValidUrl(url)) {
              window.open(url);
            }
          });
          commit("reOrderClear");
          resolve(project);
        },
        error => {
          reject(
            "We couldn't process your order. Please contact our support team."
          );
        }
      );
    });
  },
  orderDetails({ getters, dispatch }, order) {
    return new Promise((resolve, reject) => {
      const items = [];

      axios.get(`${getters.url}/${order.order_number}`).then(
        async response => {
          for (const item of response.data["Songfile_Get_Order"]) {
            items.push(DataFormatter.orderItem(item));
          }

          for (const item of items) {
            if (item.noiLicense && item.noiNumber) {
              order.hasNoi = true;

              const noiLink = await dispatch("getNoiPdfLink", {
                order: order.order_number,
                noiNumber: item.noiNumber
              });

              if (noiLink) {
                item.noiLink = noiLink;
              }
            }
          }

          resolve(items);
        },
        error => {
          reject(
            "We are having trouble loading your order details. Please contact our support for help."
          );
        }
      );
    });
  },
  async getPdfLink({ getters }, { order, license }) {
    let url;

    await axios
      .get(`${getters.url}/${order}/download/${license}`)
      .then(response => {
        url = response.data.url;
      });

    return url;
  },
  async getNoiPdfLink({ getters }, { order, noiNumber }) {
    const response = await axios.get(
      `${getters.url}/${order}/noi_pdf/${noiNumber}`
    );

    if (response.data.url) return response.data.url;

    return false;
  },
  async reOrder({ commit, dispatch, rootGetters }, order) {
    let missingManufacture = false;
    const getRelease = async function(id) {
      try {
        const release = await dispatch("release/getRelease", id, {
          root: true
        });

        if (!order.braintree_id) {
          console.log("--- LEGACY LICENSE ---");
          release.legacy = true;
        }

        if (!order.manufacturing_date) missingManufacture = true;

        release.release_date = moment(
          release.release_date,
          "YYYY-MM-DD"
        ).toDate();

        return release;
      } catch (error) {
        console.log("ERROR - REORDER GET RELEASE: ", error);
        throw error;
      }
    };

    const createRelease = async function(params) {
      if (params.album == "") {
        params.album = "-";
      }
      if (params.distribution == "") {
        params.distribution = "United States";
      }

      try {
        return await dispatch(
          "release/createRelease",
          { release: params, copies: params.copies },
          { root: true }
        );
      } catch (error) {
        console.log("ERROR - REORDER CREATE RELEASE: ", error);
        throw error;
      }
    };

    // This will get number of unique songs per Release
    // [...new Set(order.items.map(i => `${i.releaseId}-${i.songCode}`))]
    const cartBefore = rootGetters["release/getAllCartItems"].length;

    const releaseIds = [...new Set(order.items.map(i => i.releaseId))];
    const songs = order.items.map(i => {
      return {
        licenseRequest: { ...i.licenseRequest },
        song_code: i.songCode,
        releaseId: i.releaseId
      };
    });

    for (const releaseId of releaseIds) {
      const release = await getRelease(releaseId);

      const newRelease = await createRelease(release);

      const uniqSongs = songs.filter((i, index, self) => {
        return (
          i.releaseId == releaseId &&
          index ===
            self.findIndex(j => {
              return JSON.stringify(j) === JSON.stringify(i);
            })
        );
      });

      for (const song of uniqSongs) {
        await dispatch(
          "release/addItemToRelease",
          { song: song, release: newRelease, reloadCart: false },
          { root: true }
        );
      }
    }

    await dispatch("release/recoverReleases", {}, { root: true });

    let success;
    if (
      rootGetters["release/getAllCartItems"].length ==
      order.items.length + cartBefore
    ) {
      success = "complete";
    } else if (rootGetters["release/getAllCartItems"].length == cartBefore) {
      success = "none";
    } else {
      success = rootGetters["release/getAllCartItems"].length - cartBefore;
    }

    commit("reOrderSuccess", success);
    commit("fromReorder", true);
    return success;
  },
  clearReOrder({ commit }) {
    commit("reOrderClear");
  }
};

const mutations = {
  reOrderSuccess(state, success) {
    state.reOrderStatus = success;
  },
  reOrderClear(state) {
    state.reOrderStatus = null;
  },
  setProjects(state, items) {
    state.list = items;
  },
  fromReorder(state, value) {
    state.fromReorder = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
