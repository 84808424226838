export default [
  { "state code": "AL", state_name: "Alabama", "country code": "USA" },
  { "state code": "AK", state_name: "Alaska", "country code": "USA" },
  { "state code": "AZ", state_name: "Arizona", "country code": "USA" },
  { "state code": "AR", state_name: "Arkansas", "country code": "USA" },
  { "state code": "CA", state_name: "California", "country code": "USA" },
  { "state code": "CO", state_name: "Colorado", "country code": "USA" },
  { "state code": "CT", state_name: "Connecticut", "country code": "USA" },
  { "state code": "DE", state_name: "Delaware", "country code": "USA" },
  { "state code": "FL", state_name: "Florida", "country code": "USA" },
  { "state code": "GA", state_name: "Georgia", "country code": "USA" },
  { "state code": "HI", state_name: "Hawaii", "country code": "USA" },
  { "state code": "ID", state_name: "Idaho", "country code": "USA" },
  { "state code": "IL", state_name: "Illinois", "country code": "USA" },
  { "state code": "IN", state_name: "Indiana", "country code": "USA" },
  { "state code": "IA", state_name: "Iowa", "country code": "USA" },
  { "state code": "KS", state_name: "Kansas", "country code": "USA" },
  { "state code": "KY", state_name: "Kentucky", "country code": "USA" },
  { "state code": "LA", state_name: "Louisiana", "country code": "USA" },
  { "state code": "ME", state_name: "Maine", "country code": "USA" },
  { "state code": "MD", state_name: "Maryland", "country code": "USA" },
  { "state code": "MA", state_name: "Massachusetts", "country code": "USA" },
  { "state code": "MI", state_name: "Michigan", "country code": "USA" },
  { "state code": "MN", state_name: "Minnesota", "country code": "USA" },
  { "state code": "MS", state_name: "Mississippi", "country code": "USA" },
  { "state code": "MO", state_name: "Missouri", "country code": "USA" },
  { "state code": "MT", state_name: "Montana", "country code": "USA" },
  { "state code": "NE", state_name: "Nebraska", "country code": "USA" },
  { "state code": "NV", state_name: "Nevada", "country code": "USA" },
  { "state code": "NH", state_name: "New Hampshire", "country code": "USA" },
  { "state code": "NJ", state_name: "New Jersey", "country code": "USA" },
  { "state code": "NM", state_name: "New Mexico", "country code": "USA" },
  { "state code": "NY", state_name: "New York", "country code": "USA" },
  { "state code": "NC", state_name: "North Carolina", "country code": "USA" },
  { "state code": "ND", state_name: "North Dakota", "country code": "USA" },
  { "state code": "OH", state_name: "Ohio", "country code": "USA" },
  { "state code": "OK", state_name: "Oklahoma", "country code": "USA" },
  { "state code": "OR", state_name: "Oregon", "country code": "USA" },
  { "state code": "PA", state_name: "Pennsylvania", "country code": "USA" },
  { "state code": "RI", state_name: "Rhode Island", "country code": "USA" },
  { "state code": "SC", state_name: "South Carolina", "country code": "USA" },
  { "state code": "SD", state_name: "South Dakota", "country code": "USA" },
  { "state code": "TN", state_name: "Tennessee", "country code": "USA" },
  { "state code": "TX", state_name: "Texas", "country code": "USA" },
  { "state code": "UT", state_name: "Utah", "country code": "USA" },
  { "state code": "VT", state_name: "Vermont", "country code": "USA" },
  { "state code": "VA", state_name: "Virginia", "country code": "USA" },
  { "state code": "WA", state_name: "Washington", "country code": "USA" },
  { "state code": "WV", state_name: "West Virginia", "country code": "USA" },
  { "state code": "WI", state_name: "Wisconsin", "country code": "USA" },
  { "state code": "WY", state_name: "Wyoming", "country code": "USA" },
  {
    "state code": "DC",
    state_name: "District of Columbia",
    "country code": "USA"
  },
  { "state code": "BC", state_name: "British Columbia", "country code": "CAN" },
  { "state code": "AB", state_name: "Alberta", "country code": "CAN" },
  { "state code": "MB", state_name: "Manitoba", "country code": "CAN" },
  { "state code": "NB", state_name: "New Brunswick", "country code": "CAN" },
  { "state code": "NS", state_name: "Nova Scotia", "country code": "CAN" },
  { "state code": "NU", state_name: "Nunavit", "country code": "CAN" },
  {
    "state code": "NL",
    state_name: "Newfoundland and Labrador",
    "country code": "CAN"
  },
  { "state code": "ON", state_name: "Ontario", "country code": "CAN" },
  {
    "state code": "PE",
    state_name: "Prince Edward Island",
    "country code": "CAN"
  },
  { "state code": "QC", state_name: "Quebec", "country code": "CAN" },
  { "state code": "SK", state_name: "Saskatchewan", "country code": "CAN" },
  {
    "state code": "NT",
    state_name: "Northwest Territories",
    "country code": "CAN"
  },
  { "state code": "YT", state_name: "Yukon", "country code": "CAN" }
];
