const countries = [
  { "country code": "SOM", "country name": "SOMALIA" },
  { "country code": "CRI", "country name": "COSTA RICA" },
  { "country code": "ZAF", "country name": "SOUTH AFRICA" },
  {
    "country code": "SGS",
    "country name": "STH GEORGIA AND STH SANDWICH ISLDS"
  },
  { "country code": "ESP", "country name": "SPAIN" },
  { "country code": "LKA", "country name": "SRI LANKA" },
  { "country code": "SDN", "country name": "SUDAN" },
  { "country code": "SUR", "country name": "SURINAME" },
  { "country code": "SJM", "country name": "SVALBARD AND JAN MAYEN ISLANDS" },
  { "country code": "SWZ", "country name": "SWAZILAND" },
  { "country code": "SWE", "country name": "SWEDEN" },
  { "country code": "CHE", "country name": "SWITZERLAND" },
  { "country code": "SYR", "country name": "SYRIAN ARAB REPUBLIC (SYRIA)" },
  { "country code": "TWN", "country name": "TAIWAN, PROVINCE OF CHINA" },
  { "country code": "TJK", "country name": "TAJIKISTAN" },
  { "country code": "TZA", "country name": "TANZANIA, UNITED REPUBLIC OF" },
  { "country code": "THA", "country name": "THAILAND" },
  { "country code": "TLS", "country name": "EAST TIMOR" },
  { "country code": "TGO", "country name": "TOGO" },
  { "country code": "TKL", "country name": "TOKELAU" },
  { "country code": "TON", "country name": "TONGA" },
  { "country code": "TTO", "country name": "TRINIDAD AND TOBAGO" },
  { "country code": "TUN", "country name": "TUNISIA" },
  { "country code": "TUR", "country name": "TURKEY" },
  { "country code": "TKM", "country name": "TURKMENISTAN" },
  { "country code": "TCA", "country name": "TURKS AND CAICOS ISLANDS" },
  { "country code": "TUV", "country name": "TUVALU" },
  { "country code": "UGA", "country name": "UGANDA" },
  { "country code": "UKR", "country name": "UKRAINE" },
  { "country code": "ARE", "country name": "UNITED ARAB EMIRATES" },
  { "country code": "GBR", "country name": "UNITED KINGDOM" },
  {
    "country code": "UMI",
    "country name": "UNITED STATES MINOR OUTLNG ISLANDS"
  },
  { "country code": "URY", "country name": "URUGUAY" },
  { "country code": "UZB", "country name": "UZBEKISTAN" },
  { "country code": "VUT", "country name": "VANUATU" },
  { "country code": "VEN", "country name": "VENEZUELA" },
  { "country code": "VNM", "country name": "VIET NAM" },
  { "country code": "VGB", "country name": "VIRGIN ISLANDS (BRITISH)" },
  { "country code": "VIR", "country name": "VIRGIN ISLANDS (U.S.)" },
  { "country code": "WLF", "country name": "WALLIS AND FUTUNA ISLANDS" },
  { "country code": "ESH", "country name": "WESTERN SAHARA" },
  { "country code": "YEM", "country name": "YEMEN" },
  { "country code": "ZMB", "country name": "ZAMBIA" },
  { "country code": "ZWE", "country name": "ZIMBABWE" },
  { "country code": "YUG", "country name": "YUGOSLAVIA" },
  { "country code": "AFG", "country name": "AFGHANISTAN" },
  { "country code": "ALB", "country name": "ALBANIA" },
  { "country code": "DZA", "country name": "ALGERIA" },
  { "country code": "ASM", "country name": "AMERICAN SAMOA" },
  { "country code": "AND", "country name": "ANDORRA" },
  { "country code": "AGO", "country name": "ANGOLA" },
  { "country code": "AIA", "country name": "ANGUILLA" },
  { "country code": "ATA", "country name": "ANTARCTICA" },
  { "country code": "ATG", "country name": "ANTIGUA AND BARBUDA" },
  { "country code": "ARG", "country name": "ARGENTINA" },
  { "country code": "ARM", "country name": "ARMENIA" },
  { "country code": "ABW", "country name": "ARUBA" },
  { "country code": "AUS", "country name": "AUSTRALIA" },
  { "country code": "AUT", "country name": "AUSTRIA" },
  { "country code": "AZE", "country name": "AZERBAIJAN" },
  { "country code": "BHS", "country name": "BAHAMAS" },
  { "country code": "BHR", "country name": "BAHRAIN" },
  { "country code": "BGD", "country name": "BANGLADESH" },
  { "country code": "BRB", "country name": "BARBADOS" },
  { "country code": "BLR", "country name": "BELARUS" },
  { "country code": "BEL", "country name": "BELGIUM" },
  { "country code": "BLZ", "country name": "BELIZE" },
  { "country code": "BEN", "country name": "BENIN" },
  { "country code": "BMU", "country name": "BERMUDA" },
  { "country code": "BTN", "country name": "BHUTAN" },
  { "country code": "BOL", "country name": "BOLIVIA" },
  { "country code": "BIH", "country name": "BOSNIA-HERZEGOVINA" },
  { "country code": "BWA", "country name": "BOTSWANA" },
  { "country code": "BVT", "country name": "BOUVET ISLAND" },
  { "country code": "BRA", "country name": "BRAZIL" },
  { "country code": "IOT", "country name": "BRITISH INDIAN OCEAN TERRITORY" },
  { "country code": "BRN", "country name": "BRUNEI DARUSSALAM" },
  { "country code": "BGR", "country name": "BULGARIA" },
  { "country code": "BFA", "country name": "BURKINA FASO" },
  { "country code": "BDI", "country name": "BURUNDI" },
  { "country code": "KHM", "country name": "CAMBODIA" },
  { "country code": "CMR", "country name": "CAMEROON" },
  { "country code": "CPV", "country name": "CAPE VERDE" },
  { "country code": "CYM", "country name": "CAYMAN ISLANDS" },
  { "country code": "CAF", "country name": "CENTRAL AFRICAN REPUBLIC" },
  { "country code": "TCD", "country name": "CHAD" },
  { "country code": "CHL", "country name": "CHILE" },
  { "country code": "CHN", "country name": "CHINA" },
  { "country code": "CXR", "country name": "CHRISTMAS ISLAND" },
  { "country code": "CCK", "country name": "COCOS (KEELING) ISLANDS" },
  { "country code": "COL", "country name": "COLOMBIA" },
  { "country code": "COM", "country name": "COMOROS" },
  { "country code": "COG", "country name": "CONGO" },
  { "country code": "COD", "country name": "ZAIRE" },
  { "country code": "COK", "country name": "COOK ISLANDS" },
  { "country code": "HRV", "country name": "CROATIA (LOCAL NAME: HRVATSKA)" },
  { "country code": "CUB", "country name": "CUBA" },
  { "country code": "CYP", "country name": "CYPRUS" },
  { "country code": "CZE", "country name": "CZECH REPUBLIC" },
  { "country code": "CIV", "country name": "COTE D'IVOIRE (IVORY COAST)" },
  { "country code": "DNK", "country name": "DENMARK" },
  { "country code": "DJI", "country name": "DJIBOUTI" },
  { "country code": "DMA", "country name": "DOMINICA" },
  { "country code": "DOM", "country name": "DOMINICAN REPUBLIC" },
  { "country code": "ECU", "country name": "ECUADOR" },
  { "country code": "EGY", "country name": "EGYPT" },
  { "country code": "SLV", "country name": "EL SALVADOR" },
  { "country code": "GNQ", "country name": "EQUATORIAL GUINEA" },
  { "country code": "ERI", "country name": "ERITREA" },
  { "country code": "EST", "country name": "ESTONIA" },
  { "country code": "ETH", "country name": "ETHIOPIA" },
  { "country code": "FLK", "country name": "FALKLAND ISLANDS (MALVINAS)" },
  { "country code": "FRO", "country name": "FAROE ISLANDS" },
  { "country code": "FJI", "country name": "FIJI" },
  { "country code": "FIN", "country name": "FINLAND" },
  { "country code": "FXX", "country name": "FRANCE, METROPOLITAN" },
  { "country code": "FRA", "country name": "FRANCE" },
  { "country code": "GUF", "country name": "FRENCH GUIANA" },
  { "country code": "PYF", "country name": "FRENCH POLYNESIA" },
  { "country code": "ATF", "country name": "FRENCH SOUTHERN TERRITORIES" },
  { "country code": "GAB", "country name": "GABON" },
  { "country code": "GMB", "country name": "GAMBIA" },
  { "country code": "GEO", "country name": "GEORGIA" },
  { "country code": "DEU", "country name": "GERMANY" },
  { "country code": "GHA", "country name": "GHANA" },
  { "country code": "GIB", "country name": "GIBRALTAR" },
  { "country code": "GRC", "country name": "GREECE" },
  { "country code": "GRL", "country name": "GREENLAND" },
  { "country code": "GRD", "country name": "GRENADA" },
  { "country code": "GLP", "country name": "GUADELOUPE" },
  { "country code": "GUM", "country name": "GUAM" },
  { "country code": "GTM", "country name": "GUATEMALA" },
  { "country code": "GIN", "country name": "GUINEA" },
  { "country code": "GNB", "country name": "GUINEA-BISSAU" },
  { "country code": "GUY", "country name": "GUYANA" },
  { "country code": "HTI", "country name": "HAITI" },
  { "country code": "HMD", "country name": "HEARD AND MCDONALD ISLANDS" },
  { "country code": "VAT", "country name": "VATICAN CITY STATE" },
  { "country code": "HND", "country name": "HONDURAS" },
  { "country code": "HKG", "country name": "HONG KONG" },
  { "country code": "HUN", "country name": "HUNGARY" },
  { "country code": "ISL", "country name": "ICELAND" },
  { "country code": "IND", "country name": "INDIA" },
  { "country code": "IDN", "country name": "INDONESIA" },
  { "country code": "IRN", "country name": "IRAN (ISLAMIC REPUBLIC OF)" },
  { "country code": "IRQ", "country name": "IRAQ" },
  { "country code": "IRL", "country name": "IRELAND" },
  { "country code": "ISR", "country name": "ISRAEL" },
  { "country code": "ITA", "country name": "ITALY" },
  { "country code": "JAM", "country name": "JAMAICA" },
  { "country code": "JPN", "country name": "JAPAN" },
  { "country code": "JOR", "country name": "JORDAN" },
  { "country code": "KAZ", "country name": "KAZAKHSTAN" },
  { "country code": "KEN", "country name": "KENYA" },
  { "country code": "KIR", "country name": "KIRIBATI" },
  {
    "country code": "PRK",
    "country name": "KOREA, DEMOCRATIC PEOPLE'S REP OF"
  },
  { "country code": "KOR", "country name": "KOREA, REPUBLIC OF (SOUTH KOREA)" },
  { "country code": "KWT", "country name": "KUWAIT" },
  { "country code": "KGZ", "country name": "KYRGYZSTAN" },
  {
    "country code": "LAO",
    "country name": "LAOS PEOPLE'S DEMOCRATIC REPUBLIC"
  },
  { "country code": "LVA", "country name": "LATVIA" },
  { "country code": "LBN", "country name": "LEBANON" },
  { "country code": "LSO", "country name": "LESOTHO" },
  { "country code": "LBR", "country name": "LIBERIA" },
  { "country code": "LBY", "country name": "LIBYAN ARAB JAMAHIRIYA (LIBYA)" },
  { "country code": "LIE", "country name": "LIECHTENSTEIN" },
  { "country code": "LTU", "country name": "LITHUANIA" },
  { "country code": "LUX", "country name": "LUXEMBOURG" },
  { "country code": "MAC", "country name": "MACAU" },
  { "country code": "MKD", "country name": "MACEDONIA, THE FORMER YUGOSLAV" },
  { "country code": "MDG", "country name": "REPUBLIC OF MADAGASCAR" },
  { "country code": "MWI", "country name": "MALAWI" },
  { "country code": "MYS", "country name": "MALAYSIA" },
  { "country code": "MDV", "country name": "MALDIVES" },
  { "country code": "MLI", "country name": "MALI" },
  { "country code": "MLT", "country name": "MALTA" },
  { "country code": "MHL", "country name": "MARSHALL ISLANDS" },
  { "country code": "MTQ", "country name": "MARTINIQUE" },
  { "country code": "MRT", "country name": "MAURITANIA" },
  { "country code": "MUS", "country name": "MAURITIUS" },
  { "country code": "MYT", "country name": "MAYOTTE" },
  { "country code": "MEX", "country name": "MEXICO" },
  { "country code": "FSM", "country name": "MICRONESIA, FEDERATED STATES OF" },
  { "country code": "MDA", "country name": "MOLDOVA, REPUBLIC OF" },
  { "country code": "MCO", "country name": "MONACO" },
  { "country code": "MNG", "country name": "MONGOLIA" },
  { "country code": "MSR", "country name": "MONTSERRAT" },
  { "country code": "MAR", "country name": "MOROCCO" },
  { "country code": "MOZ", "country name": "MOZAMBIQUE" },
  { "country code": "MMR", "country name": "MYANMAR (FORMERLY BURMA)" },
  { "country code": "NAM", "country name": "NAMIBIA" },
  { "country code": "NRU", "country name": "NAURU" },
  { "country code": "NPL", "country name": "NEPAL" },
  { "country code": "NLD", "country name": "NETHERLANDS" },
  { "country code": "ANT", "country name": "NETHERLANDS ANTILLES" },
  { "country code": "NCL", "country name": "NEW CALEDONIA" },
  { "country code": "NZL", "country name": "NEW ZEALAND" },
  { "country code": "NIC", "country name": "NICARAGUA" },
  { "country code": "NER", "country name": "NIGER" },
  { "country code": "NGA", "country name": "NIGERIA" },
  { "country code": "NIU", "country name": "NIUE" },
  { "country code": "NFK", "country name": "NORFOLK ISLAND" },
  { "country code": "MNP", "country name": "NORTHERN MARIANA ISLANDS" },
  { "country code": "NOR", "country name": "NORWAY" },
  { "country code": "OMN", "country name": "OMAN" },
  { "country code": "PAK", "country name": "PAKISTAN" },
  { "country code": "PLW", "country name": "PALAU" },
  { "country code": "PAN", "country name": "PANAMA" },
  { "country code": "PNG", "country name": "PAPUA NEW GUINEA" },
  { "country code": "PRY", "country name": "PARAGUAY" },
  { "country code": "PER", "country name": "PERU" },
  { "country code": "PHL", "country name": "PHILIPPINES" },
  { "country code": "PCN", "country name": "PITCAIRN" },
  { "country code": "POL", "country name": "POLAND" },
  { "country code": "PRT", "country name": "PORTUGAL" },
  { "country code": "PRI", "country name": "PUERTO RICO" },
  { "country code": "QAT", "country name": "QATAR" },
  { "country code": "ROU", "country name": "ROMANIA" },
  { "country code": "RUS", "country name": "RUSSIAN FEDERATION" },
  { "country code": "RWA", "country name": "RWANDA" },
  { "country code": "REU", "country name": "REUNION" },
  { "country code": "SHN", "country name": "ST. HELENA" },
  { "country code": "KNA", "country name": "SAINT KITTS AND NEVIS" },
  { "country code": "LCA", "country name": "SAINT LUCIA" },
  { "country code": "SPM", "country name": "ST. PIERRE AND MIQUELON" },
  { "country code": "VCT", "country name": "SAINT VINCENT AND THE GRENADINES" },
  { "country code": "WSM", "country name": "SAMOA" },
  { "country code": "SMR", "country name": "SAN MARINO" },
  { "country code": "STP", "country name": "SAO TOME AND PRINCIPE" },
  { "country code": "SAU", "country name": "SAUDI ARABIA" },
  { "country code": "SEN", "country name": "SENEGAL" },
  { "country code": "SYC", "country name": "SEYCHELLES" },
  { "country code": "SLE", "country name": "SIERRA LEONA" },
  { "country code": "SGP", "country name": "SINGAPORE" },
  { "country code": "SVK", "country name": "SLOVAKIA (SLOVAK REPUBLIC)" },
  { "country code": "SVN", "country name": "SLOVENIA" },
  { "country code": "SLB", "country name": "SOLOMON ISLANDS" }
];

countries.sort((a, b) =>
  a["country name"] > b["country name"]
    ? 1
    : b["country name"] > a["country name"]
    ? -1
    : 0
);

countries.unshift({ "country code": "CAN", "country name": "Canada" });
countries.unshift({
  "country code": "USA",
  "country name": "United States of America"
});
export default countries;
