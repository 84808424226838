<template>
  <div id="app">
    <router-view />

    <md-dialog
      :md-active.sync="showNoiAlert"
      :md-close-on-esc="false"
      :md-click-outside-to-close="false"
      class="noi-alert"
    >
      <md-dialog-title>Action required</md-dialog-title>

      <p class="md-title">You need to update your account</p>

      <md-dialog-actions>
        <md-button class="md-primary" @click="updateAccount">
          Take me there!
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo: function() {
    return {
      titleTemplate: "Songfile%s"
    };
  },
  data: function() {
    return {
      showNoiAlert: false
    };
  },
  computed: {
    isNoiReady() {
      return this.$store.getters["auth/isNOIReady"];
    }
  },
  watch: {
    // eslint-disable-next-line prettier/prettier
    "$route": function() {
      this.openNoiAlert();
    },
    isNoiReady: function(value) {
      this.openNoiAlert();
    }
  },
  methods: {
    updateAccount: function() {
      this.$router.push({ name: "my-profile" });
      this.showNoiAlert = false;
    },
    openNoiAlert: function() {
      if (
        !this.isNoiReady &&
        this.$route.name != null &&
        this.$route.name != "my-profile"
      ) {
        this.showNoiAlert = true;
      }
    }
  },
  mounted: function() {
    this.openNoiAlert();
    this.$cookiebot.consentBanner();

    console.log("--", process.env.VUE_APP_MAINTENANCE);
  }
};
</script>

<style lang="scss">
.test-msg {
  z-index: 99999999;
  position: absolute;
  top: 50%;
}
.noi-alert {
  .md-title {
    padding: 20px;
  }
}
.noi-alert + .md-dialog-overlay {
  z-index: 10;
}
</style>
