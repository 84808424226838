export const apiUrl = () => {
  if (process.env.VUE_APP_API_URL) return process.env.VUE_APP_API_URL;

  if (window.location.hostname == "qa.songfile.com")
    return "https://qa-api.sesac.com/songfile/v1";

  if (
    window.location.hostname == "www.songfile.com" ||
    window.location.hostname == "songfile.com"
  )
    return "https://api.sesac.com/songfile/v1";

  return "https://qa-api.sesac.com/songfile/v1";
};
