<template>
  <div id="maintenance-mode">
    <img src="@/assets/logo-white.png" class="logo" />
    <h1>We'll be back soon!</h1>
    <h2>
      Sorry for the inconvenience but we're performing some maintenance at the
      moment.<br />If you need us you can also contact us at
      <a href="mailto:clientservices@harryfox.com"
        >clientservices@harryfox.com</a
      >, otherwise we'll be back online shortly.
    </h2>
  </div>
</template>

<script>
export default {
  name: "Maintenance",
  metaInfo: function() {
    return {
      title: " will be back soon!",
      meta: [
        {
          name: "description",
          content:
            "We're sorry for the inconvenience but we're performing some maintenance at the moment."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  }
};
</script>

<style lang="scss">
#maintenance-mode {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#54e695+0,44b8b1+100 */
  background: #54e695; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #54e695 0%, #44b8b1 100%);
  background: -webkit-linear-gradient(-45deg, #54e695 0%, #44b8b1 100%);
  background: linear-gradient(135deg, #54e695 0%, #44b8b1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#54e695', endColorstr='#44b8b1',GradientType=1 );
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 90vw;

    @media (min-width: 1224px) {
      width: 600px;
    }
  }
  h1,
  h2 {
    color: #fff;
    font-weight: normal;
    text-align: center;
  }
  h1 {
    font-size: 20px;
    margin: 36px 0 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #fff;

    @media (min-width: 1224px) {
      font-size: 36px;
    }
  }
  h2 {
    font-size: 15px;
    margin-top: 10px;
    line-height: 1.4em;

    @media (min-width: 1224px) {
      font-size: 20px;
    }

    a {
      color: white;
      text-decoration: underline;
    }
  }
}
</style>
